exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplicativos-js": () => import("./../../../src/pages/aplicativos.js" /* webpackChunkName: "component---src-pages-aplicativos-js" */),
  "component---src-pages-campo-tatico-3-d-js": () => import("./../../../src/pages/campo-tatico-3d.js" /* webpackChunkName: "component---src-pages-campo-tatico-3-d-js" */),
  "component---src-pages-curso-analise-tatica-futebol-js": () => import("./../../../src/pages/curso-analise-tatica-futebol.js" /* webpackChunkName: "component---src-pages-curso-analise-tatica-futebol-js" */),
  "component---src-pages-curso-apostas-esportivas-js": () => import("./../../../src/pages/curso-apostas-esportivas.js" /* webpackChunkName: "component---src-pages-curso-apostas-esportivas-js" */),
  "component---src-pages-curso-edicao-video-futebol-js": () => import("./../../../src/pages/curso-edicao-video-futebol.js" /* webpackChunkName: "component---src-pages-curso-edicao-video-futebol-js" */),
  "component---src-pages-curso-trader-esportivo-js": () => import("./../../../src/pages/curso-trader-esportivo.js" /* webpackChunkName: "component---src-pages-curso-trader-esportivo-js" */),
  "component---src-pages-cursos-js": () => import("./../../../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-game-vision-pro-js": () => import("./../../../src/pages/game-vision-pro.js" /* webpackChunkName: "component---src-pages-game-vision-pro-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-nucleo-analise-tatica-js": () => import("./../../../src/pages/nucleo-analise-tatica.js" /* webpackChunkName: "component---src-pages-nucleo-analise-tatica-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

