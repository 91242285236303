// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"

export const onClientEntry = () => {
    setTimeout(() => {
      if (window.gtag) return;
  
      /* global dataLayer */
      function gtag() { dataLayer.push(arguments); }
      window.dataLayer = window.dataLayer || [];
      window.gtag = gtag;
  
      gtag('js', new Date());
      gtag('config', 'G-7RQC8BEE2R');
  
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-7RQC8BEE2R`;
      script.async = true;
      document.head.appendChild(script);
    }, 5000);
  };